import React from "react";
import { Box, Flex, Icon, Switch, Text } from "@chakra-ui/react";
import { RiErrorWarningFill } from "react-icons/ri";

const SwitchInput = ({
  label,
  subtitle,

  onChange = () => {},
  isChecked = false,

  errorMessage = "Something is wrong",

  labelProps = {},

  wrapperProps = {},

  ...props
}) => {
  const { isInvalid } = props;
  const hasLabel = Boolean(label);
  const hasSubtitle = Boolean(subtitle);

  return (
    <Box w="100%" {...wrapperProps}>
      <Flex w="100%" align="center" gap="0.5em">
        <Switch onChange={onChange} isChecked={isChecked} {...props} />

        {hasLabel && <Text {...labelProps}>{label}</Text>}
      </Flex>
      {hasSubtitle && (
        <Text variant="subtitle" mt=".5em" color="mandala.black.500">
          {subtitle}
        </Text>
      )}
      {isInvalid && (
        <Flex align="center" color="mandala.red.500" mt=".5em">
          <Icon as={RiErrorWarningFill} />
          <Text variant="subtitle" color="mandala.red.500" px=".5em">
            {errorMessage}
          </Text>
        </Flex>
      )}
    </Box>
  );
};

export default SwitchInput;
